import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

import SoundCloud from '../assets/icons/soundcloud.svg';
import Spotify from '../assets/icons/spotify.svg';
import Apple from '../assets/icons/apple.svg';
import Instagram from '../assets/icons/instagram.svg';
import YouTube from '../assets/icons/youtube.svg';
import Play from '../assets/icons/play.svg';
import ExternalLink from '../assets/icons/external-link.svg';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import ObserverSection from '../components/ObserverSection';
import { getImage } from 'gatsby-plugin-image';

const Education = () => {
  const data = useStaticQuery(graphql`
    query EducationQuery {
      allWpEducationArticles {
        articles: nodes {
          id
          articleDetails {
            source
            thumbnail {
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED)
                }
              }
            }
            title
            url
          }
        }
      }
      allWpPodcast {
        podcasts: nodes {
          id
          details {
            applePodcast
            episodeName
            podcastName
            soundcloud
            spotifyPodcast
            instagram
            youtube
            cover {
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, height: 512)
                }
              }
            }
          }
        }
      }
      allWpVideo {
        videos: nodes {
          id
          videosDetails {
            title
            url
            animatedThumbnail {
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, height: 512)
                }
              }
            }
            thumbnail {
              localFile {
                url
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, height: 512)
                }
              }
            }
          }
        }
      }
      access: allWpElelementsAccess {
        nodes {
          visibility {
            showEducationArticles
            showEducationVideos
          }
        }
      }
    }
  `);

  const intl = useIntl();
  const [filter, setFilter] = useState('podcasts');

  const displayData = () => {
    if (filter === 'podcasts') {
      return data?.allWpPodcast?.podcasts || [];
    }

    if (filter === 'articles') {
      return data?.allWpEducationArticles?.articles || [];
    }
    return data?.allWpVideo?.videos || [];
  };

  const filteredData = displayData();

  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: 'education.title' })} />
      <div className="page-title">
        {intl.formatMessage({ id: 'education.title' })}
      </div>
      <div className="page-title-underline" />
      {(data?.access?.nodes?.length === 0 ||
        (data?.access?.nodes?.length > 0 &&
          (data?.access?.nodes[0]?.visibility?.showEducationArticles ||
            data?.access?.nodes[0]?.visibility?.showEducationVideos))) && (
        <div className="media-articles-filters">
          <button
            className={`media-articles-filter-option${
              filter === 'podcasts' ? ' active' : ''
            }`}
            onClick={() => {
              setFilter('podcasts');
            }}
          >
            {intl.formatMessage({ id: 'education.podcasts' })}
          </button>
          {(data?.access?.nodes?.length === 0 ||
            (data?.access?.nodes?.length > 0 &&
              data?.access?.nodes[0]?.visibility?.showEducationArticles)) && (
            <button
              className={`media-articles-filter-option${
                filter === 'articles' ? ' active' : ''
              }`}
              onClick={() => {
                setFilter('articles');
              }}
            >
              {intl.formatMessage({ id: 'education.articles' })}
            </button>
          )}
          {(data?.access?.nodes?.length === 0 ||
            (data?.access?.nodes?.length > 0 &&
              data?.access?.nodes[0]?.visibility?.showEducationVideos)) && (
            <button
              className={`media-articles-filter-option${
                filter === 'videos' ? ' active' : ''
              }`}
              onClick={() => {
                setFilter('videos');
              }}
            >
              {intl.formatMessage({ id: 'education.videos' })}
            </button>
          )}
        </div>
      )}
      {(filteredData?.length > 0 && (
        <div className="media-articles-wrapper">
          {filteredData?.map(el => {
            if (filter === 'podcasts') {
              return (
                <ObserverSection className="podcast-card" key={el?.id}>
                  <div
                    className="podcast-thumbnail"
                    style={{
                      backgroundImage: `url(${
                        el?.details?.cover?.localFile?.url
                      }), url(${
                        getImage(el?.details?.cover?.localFile)?.placeholder
                          ?.fallback
                      })`,
                    }}
                  />
                  <div className="card-title-container">
                    <div className="card-title-scroller">
                      <div className="card-title">
                        {el?.details?.episodeName}
                      </div>
                    </div>
                    <div className="card-source">
                      {el?.details?.podcastName}
                    </div>
                  </div>
                  <div className="card-actions-container">
                    <div className="card-action-name">
                      {intl.formatMessage({ id: 'education.listen' })}
                    </div>
                    <div className="card-actions">
                      {el?.details?.youtube && (
                        <a
                          className="podcast-link"
                          href={el.details.youtube}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <YouTube />
                        </a>
                      )}
                      {el?.details?.instagram && (
                        <a
                          className="podcast-link"
                          href={el.details.instagram}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Instagram />
                        </a>
                      )}
                      {el?.details?.soundcloud && (
                        <a
                          className="podcast-link"
                          href={el.details.soundcloud}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <SoundCloud />
                        </a>
                      )}
                      {el?.details?.spotifyPodcast && (
                        <a
                          className="podcast-link"
                          href={el.details.spotifyPodcast}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Spotify />
                        </a>
                      )}
                      {el?.details?.applePodcast && (
                        <a
                          className="podcast-link"
                          href={el.details.applePodcast}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Apple />
                        </a>
                      )}
                    </div>
                  </div>
                </ObserverSection>
              );
            }
            if (filter === 'videos') {
              return (
                <a
                  href={el?.videosDetails?.url}
                  target="_blank"
                  rel="noreferrer"
                  key={el?.id}
                >
                  <ObserverSection className="video-card">
                    <div
                      className="video-thumbnail-static"
                      style={{
                        backgroundImage: `url(${
                          el?.videosDetails?.thumbnail?.localFile?.url
                        }), url(${
                          getImage(el?.videosDetails?.thumbnail?.localFile)
                            ?.placeholder?.fallback
                        })`,
                      }}
                    >
                      <div
                        className="video-thumbnail-animated"
                        style={{
                          backgroundImage: `url(${
                            el?.videosDetails?.animatedThumbnail?.localFile?.url
                          }), url(${
                            getImage(
                              el?.videosDetails?.animatedThumbnail?.localFile
                            )?.placeholder?.fallback
                          })`,
                        }}
                      />
                    </div>
                    <div className="card-title-container">
                      <div className="card-title">
                        {el?.videosDetails?.title}
                      </div>
                      <div className="card-source">
                        {el?.details?.podcastName}
                      </div>
                    </div>
                    <div className="card-actions-container">
                      <div className="card-action-name">
                        {intl.formatMessage({ id: 'education.watch' })}
                      </div>
                      <div className="card-actions">
                        <Play />
                      </div>
                    </div>
                  </ObserverSection>
                </a>
              );
            }
            return (
              <a
                key={el?.id}
                href={el?.articleDetails?.url}
                target="_blank"
                rel="noreferrer"
              >
                <ObserverSection className="podcast-card">
                  <div
                    className="podcast-thumbnail"
                    style={{
                      backgroundImage: `url(${
                        el?.articleDetails?.thumbnail?.localFile?.url
                      }), url(${
                        getImage(el?.articleDetails?.thumbnail?.localFile)
                          ?.placeholder?.fallback
                      })`,
                    }}
                  />
                  <div className="card-title-container">
                    <div className="card-title">
                      {el?.articleDetails?.title}
                    </div>
                    <div className="card-source">
                      {el?.articleDetails?.source}
                    </div>
                  </div>
                  <div className="card-actions-container">
                    <div className="card-action-name">
                      {intl.formatMessage({ id: 'education.read' })}
                    </div>
                    <div className="card-actions">
                      <ExternalLink />
                    </div>
                  </div>
                </ObserverSection>
              </a>
            );
          })}
        </div>
      )) || (
        <div className="no-media-articles">
          {intl.formatMessage({ id: 'education.nothing' })}
        </div>
      )}
    </Layout>
  );
};

export default Education;
